import "../style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { IonItem } from "@ionic/react";
import { locationTrackingHelper } from "@src/app/locationTracking/locationTracking.helper";
import { TripTrackingTrigger } from "@src/app/locationTracking/locationTracking.types";
import { useRateNegotiationIsActive } from "@src/app/rateNegotiation/hooks/useRateNegotiationIsActive";
import { setRecognizedHoliday } from "@src/app/store/session";
import { isShiftPriorityValid } from "@src/app/utils/shiftHelper";
import { logEvent } from "@src/appV2/lib/analytics";
import { BookabilityDecision, BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction";
import { useATeamStatus } from "@src/appV2/Rankings/useATeamStatus";
import { Shift as ShiftV2, ShiftWindow, UrgencyType } from "@src/appV2/Shifts/Shift/types";
import { useGetStreaksSegmentData } from "@src/appV2/Streaks/useGetStreaksSegmentData";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { Facility } from "@src/lib/interface";
import { makeInstantpayLogParameters } from "@src/utils/logEvent";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { ShiftItemView } from "./shiftItemView";
import {
  HCF_PROFILE_SOURCE,
  SEARCH_MODE,
  SHIFT_BOOKED_CONTEXT,
  USER_EVENTS,
} from "../../../constants";
import { ShiftItemProps } from "../model";

export function ShiftItem(props: ShiftItemProps) {
  const {
    shift,
    updateShiftList,
    updateShiftFields,
    onFacilityDetailsClick,
    isHcpInstantPayEnabled,
    isDisabled,
    className = "",
    showFacilityDetails = true,
    onItemClick,
    isUrgentShift,
    searchMode,
    hcfProfileSource,
    shiftSlots,
    setClickedInterestInShift,
    allowedAgentReq,
    shiftBookability,
    isShiftBookabilityLoading = false,
    isForFacilityOpenShift,
  } = props;
  const { isWorkerAteamEnabled } = useATeamStatus();

  const sourceContext: string | null = useMemo(() => {
    let context: string | null = null;

    if (hcfProfileSource) {
      if (hcfProfileSource === HCF_PROFILE_SOURCE.CALENDAR) {
        context = SHIFT_BOOKED_CONTEXT.HCF_CALENDAR;
      } else if (hcfProfileSource === HCF_PROFILE_SOURCE.MAP) {
        context = SHIFT_BOOKED_CONTEXT.HCF_MAP;
      }
    } else if (searchMode === SEARCH_MODE.TIME_SLOT) {
      context = SHIFT_BOOKED_CONTEXT.CALENDAR_DATE;
    } else if (searchMode === SEARCH_MODE.URGENT_SHIFTS) {
      context = SHIFT_BOOKED_CONTEXT.URGENT_SHIFTS;
    } else if (searchMode === SEARCH_MODE.NON_QFA_RANGE_SHIFTS) {
      context = SHIFT_BOOKED_CONTEXT.NON_QFA_RANGE_SHIFTS;
    }

    if (isUrgentShift) {
      context = SHIFT_BOOKED_CONTEXT.URGENT_SHIFTS;
    } else if (searchMode) {
      context = SHIFT_BOOKED_CONTEXT[searchMode];
    }

    return context;
  }, [searchMode, hcfProfileSource, isUrgentShift]);

  const worker = useDefinedWorker();

  const isInstantBookingShift =
    isDefined(shiftBookability) &&
    (shiftBookability.decision === BookabilityDecision.ALLOWED ||
      shiftBookability.decision === BookabilityDecision.UNDETERMINED ||
      (shiftBookability.decision === BookabilityDecision.BLOCKED &&
        shiftBookability.unmetCriteria?.[0] ===
          BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES));

  const isRateNegotiationActive =
    useRateNegotiationIsActive(shift) && !isUrgentShift && isInstantBookingShift;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isInstantBookingShift) {
      logEvent(USER_EVENTS.DISCOVERED_AN_INSTANT_BOOK_SHIFT);
    }
  }, [isInstantBookingShift]);

  useEffect(() => {
    if (shift.isHolidayShift) {
      dispatch(setRecognizedHoliday(shift.holidayName ?? ""));
    }
  }, [dispatch, shift.holidayName, shift.isHolidayShift]);

  const isShiftInstantPayAllowed = shift.isInstantPay ?? !shift.isHCFInstantPayProhibited;
  const isInstantPayAllowed = isShiftInstantPayAllowed && isHcpInstantPayEnabled;

  const onClickOnFacilityDetails = (displayExtraTimePayCard = false) => {
    const facility = shift?.facility as Facility;
    /**
     * Note - this is directly mutating an object in redux store, which could
     * cause bugs.
     */
    facility.isInstantPayEnabled = !shift.isHCFInstantPayProhibited;
    onFacilityDetailsClick?.(shift, isInstantBookingShift as boolean, displayExtraTimePayCard);
  };

  const [priorityShift, setPriorityShift] = useState(
    shift?.priorityTill ? isShiftPriorityValid(shift.priorityTill) : false
  );
  const shiftSlot = shiftSlots?.find((shiftSlot) => {
    if (
      shift.start === shiftSlot.start.toString() &&
      shift.end === shiftSlot.end.toString() &&
      shift?.facilityId?.toString() === shiftSlot.workplaceUserId
    ) {
      return true;
    }
    return false;
  });

  const { shouldEmitSegmentEvent, payBoostPercentage } = useGetStreaksSegmentData(worker.userId);
  const streaksMetaData = shouldEmitSegmentEvent
    ? {
        streaksBonus: (shift.finalPay ?? 0) * (payBoostPercentage ?? 0),
      }
    : {};

  const isShiftATeamWindowed = isWorkerAteamEnabled && shift.window === ShiftWindow.A_TEAM;

  return (
    <IonItem
      className={`${
        shift.agentId !== worker.userId && (priorityShift === true || isShiftATeamWindowed)
          ? "shift-item__container-shift-priority"
          : "shift-item__container"
      } ${className}`}
      data-testid={`shift-item-container-${shift.shiftId}`}
      disabled={isDisabled}
    >
      <ShiftItemView
        onClickOnFacilityDetails={onClickOnFacilityDetails}
        shiftDetails={shift}
        isInstantPayAllowed={isInstantPayAllowed as boolean}
        showFacilityDetails={showFacilityDetails}
        onItemClick={onItemClick}
        isUrgentShift={isUrgentShift}
        priorityShift={priorityShift}
        setPriorityShift={setPriorityShift}
        isShiftATeamWindowed={isShiftATeamWindowed}
        shiftSlot={shiftSlot}
        isRateNegotiationActive={isRateNegotiationActive}
        updateShiftFields={updateShiftFields}
        allowedAgentReq={allowedAgentReq}
        searchMode={searchMode}
        shiftBookability={shiftBookability}
        isShiftBookabilityLoading={isShiftBookabilityLoading}
        onBookShift={async (bookedShift) => {
          setClickedInterestInShift?.(true);

          logEvent(USER_EVENTS.BOOK_INSTANTBOOK_SHIFT, {
            ...makeInstantpayLogParameters(
              shift,
              isHcpInstantPayEnabled && shift?.isHCFInstantPayProhibited === false,
              worker
            ),
            instant_book: true,
            hcpId: worker.userId,
            email: worker.email,
            context: sourceContext,
            workplaceId: shift?.facilityId,
            facilityType: shift?.facility?.type,
            ...streaksMetaData,
          });

          updateShiftList?.(shift as ShiftV2, true, bookedShift);

          if (shift.urgency === UrgencyType.NCNS) {
            try {
              await locationTrackingHelper.handleTrackingModeChange({
                agentId: worker.userId,
                trigger: TripTrackingTrigger.urgentShiftBooking,
              });
            } catch (error) {
              logEvent(USER_EVENTS.TRIP_TRACKING_ERROR, {
                shiftId: shift._id,
                shiftStart: shift.start,
                workerId: worker.userId,
                error: error.message,
              });
            }
          }
        }}
        onShiftMarkInterest={() => {
          setClickedInterestInShift?.(true);

          logEvent(USER_EVENTS.MARKED_INTEREST_IN_SHIFT, {
            hcpUserId: worker.userId,
            hcpName: worker.name,
            shiftId: shift._id,
            hcpQualification: worker.preference?.qualification as string,
            shiftName: shift.name,
            facilityName: shift.facility?.name,
            facilityId: shift.facility?._id,
            startAt: shift.start,
            endAt: shift.end,
          });

          updateShiftList?.(shift as ShiftV2, false, {});
        }}
        isForFacilityOpenShift={isForFacilityOpenShift}
      />
    </IonItem>
  );
}
