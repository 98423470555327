import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useTheme,
} from "@mui/material";
import { formatShiftHours } from "@src/app/dayView/utils/hours";

export interface MandatoryLunchInformation {
  facilityName: string;
  shiftTime: number;
}

export interface LunchBreakDialog {
  isOpen: boolean;
  shiftDetails: MandatoryLunchInformation;
  closeModal: () => void;
}
export function LunchBreakInformationDialog({
  isOpen,
  shiftDetails,
  closeModal,
}: LunchBreakDialog) {
  const theme = useTheme();
  return (
    <Dialog
      PaperProps={{
        sx: {
          paddingY: 1,
        },
      }}
      sx={{ padding: 2 }}
      open={isOpen}
      onClose={() => closeModal()}
      aria-label="Lunch Break Information Dialog"
    >
      <DialogTitle sx={{ fontSize: theme.typography.h4, fontWeight: 600 }}>
        {shiftDetails?.facilityName} requires a mandatory 30 minute break!
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: theme.typography.body2 }}>
          The full duration of this shift is {formatShiftHours(shiftDetails?.shiftTime ?? 0)}. Your
          pay will reflect that you worked {formatShiftHours(shiftDetails?.shiftTime, false)}{" "}
          because a .5 hour unpaid break is mandatory at this facility.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 0 }}>
        <Button fullWidth onClick={() => closeModal()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
