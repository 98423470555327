import { Text } from "@clipboard-health/ui-react";
import BlueCheckIcon from "@src/assets/icons/blue-check.svg";

export interface ShiftItemStatusProps {
  showSentHomeMessage?: boolean;
}

export function ShiftItemStatusLine(props: ShiftItemStatusProps) {
  const { showSentHomeMessage } = props;

  if (showSentHomeMessage) {
    return <Text variant="body1">You have a sent home request on this shift</Text>;
  }

  return (
    <Text variant="body1">
      <img src={BlueCheckIcon} alt="" />
      You&apos;re Working This Shift!
    </Text>
  );
}
