import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Stack } from "@mui/material";

interface Props {
  isInfoButtonVisible: boolean;
  shiftDuration: string;
  onInfoButtonClick: () => void;
}
export function ShiftDurationWithAction(props: Props) {
  const { shiftDuration, isInfoButtonVisible, onInfoButtonClick } = props;

  return (
    <>
      (
      <Stack component="span" display="inline-flex" direction="row" spacing={1}>
        {shiftDuration.length > 0 ? shiftDuration : null}
        {isInfoButtonVisible ? (
          <IconButton
            sx={{ paddingLeft: 0.25, paddingY: 0, paddingRight: 0 }}
            size="small"
            aria-label="Mandatory Lunch Break Button"
            onClick={onInfoButtonClick}
          >
            <InfoIcon color="primary" fontSize="small" />
          </IconButton>
        ) : null}
      </Stack>
      )
    </>
  );
}
