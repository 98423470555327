import { Text } from "@clipboard-health/ui-react";
import { Divider, Stack } from "@mui/material";
import { TOTAL_PAY_COLOR } from "@src/appV2/lib/Theme/constants";
import { ReactElement } from "react";

export interface PayRateTextProps {
  /**
   * Element showing Value of total pay of the shift or hourly pay. @see ShiftPayRate
   */
  totalPay: ReactElement | string;

  isInstantPay?: boolean;
}

export function PayRateText(props: PayRateTextProps) {
  const { totalPay, isInstantPay } = props;

  return (
    <Stack
      spacing={1}
      direction="row"
      /**
       * In order for the divider to correctly extend to 80% of it's parent, the parent needs to have a fixed height.
       * If we don't set this, the divider would be just a point on the screen.
       */
      height="100%"
      divider={
        <Divider
          orientation="vertical"
          sx={{ borderWidth: 1, height: "80%", alignSelf: "center" }}
          flexItem
        />
      }
    >
      <Text sx={{ color: TOTAL_PAY_COLOR }} aria-label="Total Pay Rate For this Shift">
        {totalPay}
      </Text>
      {isInstantPay && (
        <Text bold sx={{ color: (theme) => theme.palette.success.light }}>
          InstantPay
        </Text>
      )}
    </Stack>
  );
}
