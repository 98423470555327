import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import useOnScreen from "@src/hooks/useOnScreen";
import { useEffect, useRef } from "react";

export interface ShiftPaginationLoaderProps {
  onLoadNextPage: () => void;
  checkElementPosition?: boolean;
}

export function ShiftPaginationLoader(props: ShiftPaginationLoaderProps) {
  const { onLoadNextPage, checkElementPosition = true } = props;
  const elementRef = useRef<HTMLIonGridElement>(null);
  const isOnScreen = useOnScreen(elementRef, 0.8);

  useEffect(() => {
    if (!isOnScreen) {
      return;
    }

    const parentElementPosition = elementRef.current?.parentElement?.getBoundingClientRect();
    const currentElementPosition = elementRef.current?.getBoundingClientRect();

    /**
     * Pagination loader appear at the top of the screen upon initial loading,
     * while it takes some time for the shift items to be painted and that triggers the bookability checks for next pages.
     * To prevent loading bookability checks for next pages simultaneously,
     * perform the bookability checks only when the loader is at least 100px away from the top or parent.
     */
    if (
      checkElementPosition &&
      (!isDefined(parentElementPosition) ||
        !isDefined(currentElementPosition) ||
        currentElementPosition.top - parentElementPosition.top < 100)
    ) {
      return;
    }

    onLoadNextPage();
  }, [isOnScreen, onLoadNextPage, checkElementPosition]);

  return <Box ref={elementRef} />;
}
